import "../css/global.css"
import React from "react"
import Header from "../components/header"

const green = "text-teal-500"

class Contact extends React.Component {
  render() {
    return (
      <div className="h-screen flex flex-col">
        <Header page="contact" />
        <div className="flex border-t border-gray-200 p-4 flex-1">
          <div className="w-full flex flex-col">
            <div className="mt-24 flex flex-col items-center">
              {/* <div className="text-xs text-gray-600">CONTACT</div> */}
              <div className="text-3xl font-bold mb-10">
                Let's build something great together
                <span className={`${green}`}>.</span>
              </div>
              <a href="mailto:info@digitify.it" className={`text-lg ${green}`}>
                info@digitify.it
              </a>
              <div className="text-gray-600 mt-1">Bali - Indonesia</div>
            </div>
            <div className="flex-1 flex items-end justify-end">
              <div className="text-gray-600">© 2019 Digitify</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
